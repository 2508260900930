body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.room-btns{
    margin-top: 5rem;

  }
  
.room-btn{
  background-color: #61dafb;
  border: 2px solid #61dafb;
  color: #282c34;
  border-radius: 5px;
  padding: 10px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.room-btn:hover{
  background-color: #61dafb17;
  color: #61dafb;
  /* border: 2px solid #61dafb; */
}

.home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-input{
  width: 15rem;
  height: 1rem;
  border: none;
  outline: none;
  background: #61dafb09;
  color: white;
  border-bottom: 2px solid #61dafb69;
  padding: 10px;
  margin: 10px;
  margin-top: 5rem;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.name-input:focus{
  border-bottom: 2px solid #61dafb;
}

.error{
  position: absolute;
  top: 295px;
  font-size: 1rem;
  color: red;
}
.url-input{
    width: 8rem;
}

.copy-btn{
    font-size: 16px;
}

.copied{
    background-color: #00ff00;
}
.game{
    position: relative;
    /* border: 1px solid red; */
    margin-bottom: 10px;
}

p{
    font-size: 1rem;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}
.grid-item {
    padding: 30px;
    font-size: 60px;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #61dafb;
    border: none;
}


.grid-item-hover:hover{
    background-color: #61dafb1c;
    color: white;
    /* border: none; */
    cursor: pointer;
}

.bottom{
    border-bottom: 2px solid #61dafb;
}

.right{
    border-right: 2px solid #61dafb;
}

.line {
	background-color: green;
    box-shadow: 0px 0px 10px green;
	border-radius: 50px;
	position: absolute;

	-webkit-transform: translateY(-50%);

	        transform: translateY(-50%);
	height: 0.3rem;
	width: 0;
	-webkit-animation: grow 0.3s linear forwards;
	        animation: grow 0.3s linear forwards;
	z-index: 2;
}

@-webkit-keyframes grow {
	to {
		width: 90%;
	}
}

@keyframes grow {
	to {
		width: 90%;
	}
}

.p0{
    top: 52%;
	left: 6%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.p1{
    top: 69%;
	left: 6%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.p2{
    top: 86%;
	left: 6%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}


.p3{
    top: 70%;
	left: -27%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.p4{
    top: 70%;
	left: 5%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.p5{
    top: 70%;
	left: 37%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.p6{
    top: 70%;
	left: 5%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.p7{
    top: 70%;
	left: 5%;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}


.game-end{
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: space-evenly;
}


.loading{
    height: 420px;
    width: 390px;
    background: #61dafb00;
    position: absolute;
    /* top: 87px; */
    top: 27%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.score{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
}


@media (max-width: 768px) {
    .grid-item {
        padding: 30px;
        font-size: 60px;
        width: 40px;
        height: 40px;
    }

    .loading{
        height: 420px;
        width: 325px;
        top: 27%;
        /* border: 1px solid red; */
    }

}

.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #cccccc65;
    margin-top: 10px;
}

.upper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer p{
    padding: 0;
    /* margin-bottom: px; */
}

.footer a{
    text-decoration: none;
    color: #61dafb;
    font-size: 1.2em;
}

.social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.social a{
    padding: 0 10px;
    color: #61dafb;
    font-size: 0.8em;
}
.banner {
    width: 100%;
    color: #ccc;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #282c34, #41aecc);
    -webkit-animation: animateBackground 10s ease-in-out infinite;
            animation: animateBackground 10s ease-in-out infinite;
    background-size: 400% 400%;
  }
  
  @-webkit-keyframes animateBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes animateBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .banner a {
    color: rgb(237, 150, 75);
    font-weight:bold;
    text-decoration: none;
  }
  
  .banner a:hover {
    color: rgb(216, 16, 16);
  }
  
  .banner h2 {
    margin: 0;
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .banner {
      display: none;
    }
  }
  
  
