.banner {
    width: 100%;
    color: #ccc;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #282c34, #41aecc);
    animation: animateBackground 10s ease-in-out infinite;
    background-size: 400% 400%;
  }
  
  @keyframes animateBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .banner a {
    color: rgb(237, 150, 75);
    font-weight:bold;
    text-decoration: none;
  }
  
  .banner a:hover {
    color: rgb(216, 16, 16);
  }
  
  .banner h2 {
    margin: 0;
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .banner {
      display: none;
    }
  }
  
  